<template>
  <div class="p-4">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12 text-left">
            <h4>
              Site Maintenance
              <span
                ><b-spinner
                  small
                  label="Small Spinner"
                  v-if="isLoading"
                ></b-spinner
              ></span>
            </h4>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-12 text-left">
                    <h5>Police check</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div>
                  <dl class="row">
                    <dd class="col-sm-6 text-secondary font-weight-normal">
                      Date from
                    </dd>
                    <dd class="col-sm-6">{{ dateFrom }}</dd>
                    <dd class="col-sm-6 text-secondary font-weight-normal">
                      Number of records
                    </dd>
                    <dd class="col-sm-6">{{ totalRecordsPolice }}</dd>
                    <dd class="col-sm-6 text-secondary font-weight-normal">
                      Number of documents
                    </dd>
                    <dd class="col-sm-6">{{ totalDocPolice }}</dd>
                  </dl>
                  <div>
                    <p class="mb-0 fs-700">AWS documents deleting progress</p>
                    <b-progress :max="lastPageDocPolice" variant="info">
                      <b-progress-bar
                        :value="getCurrentDocRemoveProgress"
                        :label="`${getCurrentDocRemoveProgress}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </div>
                  <div>
                    <p class="mb-0 fs-700">Records deleting progress</p>
                    <b-progress :max="lastPageRecordPolice" variant="info">
                      <b-progress-bar
                        :value="getCurrentRecordRemoveProgress"
                        :label="`${getCurrentRecordRemoveProgress}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button class="btn btn-sm btn-danger" @click="fnRemoveDoc">
                  Delete
                  <b-spinner
                    small
                    label="Small Spinner"
                    v-if="isDeleting"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12"></div>
          <div class="col-md-4 col-12"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as messageService from "../../services/message.service";
import * as siteMaintenanceService from "../../services/siteMaintenance.service.js";

export default {
  name: "SiteMaintenance",
  components: {},
  data() {
    return {
      errors: {},
      loginUserObj: {},

      siteData: [],
      dateFrom: "",
      totalRecordsPolice: 0,
      totalDocPolice: 0,
      lastPageRecordPolice: 0,
      lastPageDocPolice: 0,
      removeDocProgressPolice: 0,
      removeRecordsProgressPolice: 0,
      isLoading: false,
      isDeleting: false,
    };
  },
  computed: {
    getCurrentDocRemoveProgress() {
      return (
        (100 / Number(this.lastPageDocPolice)) *
        Number(this.removeDocProgressPolice)
      ).toFixed(0);
    },
    getCurrentRecordRemoveProgress() {
      return (
        (100 / Number(this.lastPageRecordPolice)) *
        Number(this.removeRecordsProgressPolice)
      ).toFixed(0);
    },
  },
  methods: {
    fnGetPoliceExpiredRecords: async function () {
      this.errors = {};

      try {
        this.isLoading = true;
        let res = await siteMaintenanceService.getPoliceExpiredRecords();
        this.dateFrom = res.data.date_from;

        /**
         * Police check
         */
        const {
          last_page: lastPageRecordPolice,
          total: totalRecordsPolice,
          per_page: perPageRecordPolice,
        } = res.data.data_police_records;

        this.totalRecordsPolice = totalRecordsPolice;
        this.lastPageRecordPolice = lastPageRecordPolice;

        const {
          last_page: lastPageDocPolice,
          total: totalPagesDocPolice,
          per_page: perPageDocPolice,
        } = res.data.data_police_documents;

        this.totalDocPolice = totalPagesDocPolice;
        this.lastPageDocPolice = lastPageDocPolice;
        this.isLoading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "System settings - update",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "System settings - update",
              "Something wrong"
            );
            break;
        }
        this.isLoading = false;
      }
    },
    fnRemoveDoc: async function () {
      this.isDeleting = true;
      //await this.fnDeleteDoc(this.lastPageDocPolice);
      await this.fnDeleteRecords(this.lastPageRecordPolice);
      this.isDeleting = false;
    },
    /**
     * Delete AWS S3 document which more than one year
     * @param {*} cycles
     */
    fnDeleteDoc: async function (cycles) {
      // const cycles = this.lastPage;
      try {
        for (let i = 1; i < cycles + 1; i++) {
          this.removeDocProgressPolice = i;
          let res = await siteMaintenanceService.removeDoc({
            currentPage: i,
          });
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "AWS S3 Document - Delete",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "AWS S3 Document - Delete",
              "Something wrong"
            );
            break;
        }
        console.log(error);
      }
    },
    /**
     * Delete records in database which more than one year
     * @param {*} cycles
     */
    fnDeleteRecords: async function (cycles) {
      try {
        for (let i = 1; i < cycles + 1; i++) {
          let res = await siteMaintenanceService.removeRecord({
            currentPage: i,
          });
          this.removeRecordsProgressPolice = i;
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "CVS Records - Delete",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "CVS Records - Delete",
              "Something wrong"
            );
            break;
        }
        console.log(error);
      }
    },
  },
  created() {
    this.fnGetPoliceExpiredRecords();
  },
};
</script>
