import { http, httpFile } from './http_service';
// Get Police records summary
export function getPoliceExpiredRecords(){
    return http().get(`/site-maintenance/get-police-expired-records`);
}
// Remove documents
export function removeDoc(obj){
  return http().get(`/site-maintenance/remove-doc?page=${obj.currentPage}`);
}
// Remove records
export function removeRecord(obj){
    return http().get(`/site-maintenance/remove-record?page=${obj.currentPage}`)
}
